

import request from '@/utils/request';


const printer_list = (data) => {
    return request({
        url: '/adm/printer/list',
        method: 'post',
        data,
    });
}



export {
    printer_list,
}